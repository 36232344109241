exports.components = {
  "component---src-components-a-resource-tsx": () => import("./../../../src/components/AResource.tsx" /* webpackChunkName: "component---src-components-a-resource-tsx" */),
  "component---src-components-redirect-tsx": () => import("./../../../src/components/Redirect.tsx" /* webpackChunkName: "component---src-components-redirect-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assessmentresults-tsx": () => import("./../../../src/pages/assessmentresults.tsx" /* webpackChunkName: "component---src-pages-assessmentresults-tsx" */),
  "component---src-pages-clientassessment-tsx": () => import("./../../../src/pages/clientassessment.tsx" /* webpackChunkName: "component---src-pages-clientassessment-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-elderlaw-tsx": () => import("./../../../src/pages/elderlaw.tsx" /* webpackChunkName: "component---src-pages-elderlaw-tsx" */),
  "component---src-pages-estateplanning-tsx": () => import("./../../../src/pages/estateplanning.tsx" /* webpackChunkName: "component---src-pages-estateplanning-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-practiceareas-tsx": () => import("./../../../src/pages/practiceareas.tsx" /* webpackChunkName: "component---src-pages-practiceareas-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

